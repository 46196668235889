import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import imgInteractiveVideo from "../../assets/image/icons/interactive-video.png";
import imgInteractiveImage from "../../assets/image/icons/interactive-image.png";
import imgBioLink from "../../assets/image/icons/bio-link.png";
import imgPopups from "../../assets/image/icons/popups.png";

import iconOne from '../../assets/image/icons/overlays.png';
import iconTwo from '../../assets/image/icons/branch.png';
import iconThree from '../../assets/image/icons/hotspots.png';

const Hero = ({ backgroundImage, backgroundImage2, className, ...rest }) => {
  const widgetArray = [
    {
      title: 'Digital Asset Management (DAM)',
      icon: iconOne,
    },
    {
      title: 'Dymanic QR Codes',
      icon: iconTwo,
    },
    {
      title: 'Interactive Media',
      icon: iconThree,
    },    
  ];  

  return (
    <div className={className} {...rest}>

      <div className="container position-static mt-5">
        <div className="row align-items-center justify-content-center position-static">
          {/* Hero Image */}
          <div className="d-md-block col-xl-6 col-lg-6 col-md-6 col-xs-12 position-static order-2 order-sm-1" data-aos="zoom-in-up" data-aos-once="true" data-aos-delay="750">
            <h1 className="font-size-10 pr-sm-18 pr-md-8 pr-lg-0 line-height-84 mt-10">
              A Digital Experience Solutions Provider
              </h1>
              <h2 className="font-weight-normal font-size-6 mb-0 pr-sm-10 pr-md-10 pr-lg-0 pr-xl-18 mb-5">
              Creating and managing digital experiences through a range of services, including:</h2>
              <div className="list">
                  {widgetArray.map(({ title, icon }, index) => {
                    return (
                      <div
                        className="d-flex align-items-center mb-6"
                        key={index}
                      >
                        <div className="circle-59 bg-gray-3 mr-6">
                          <img src={icon} alt={title} />
                        </div>
                        <p
                          className="font-size-6 heading-default-color font-weight-medium mb-0"
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></p>
                      </div>
                    );
                  })}
                </div>
              <a href="https://app.digma.io?mode=login" className="d-none d-md-inline-block mt-5 mb-10 btn btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3">try for free</a>
          </div>
          <div className="d-block d-md-none p-l-10 p-5 order-1 order-sm-12">
            <div className="d-flex">
              <AniLink duration={1}  paintDrip hex="#2196f3" to="/interactive-video-player">
                <img className="w-100" src={imgInteractiveVideo} alt="Interactive Video" />
              </AniLink> 
              <AniLink duration={1}  paintDrip hex="#ff9b04" to="/interactive-images">
                <img className="w-100" src={imgInteractiveImage} alt="Interactive Images" />
              </AniLink>
              </div>
              <div className="d-flex">
              <AniLink duration={1}  paintDrip hex="#e80ad2" to="/qr-codes">
                <img className="w-100" src={imgBioLink} alt="Bio Link" />
              </AniLink>
              <AniLink duration={1}  paintDrip hex="#25b401" to="/popups">
                <img className="w-100" src={imgPopups} alt="Popups" />
              </AniLink>                       
            </div>
          </div>          
          {/* End Hero Image */}
          {/* Hero Content */}
          <div className="d-none d-md-block col-lg-6 col-md-6 col-sm-12 order-1 order-sm-12">
            <div
              className="mt-8 mt-lg-0 pr-md-11 pr-lg-0 pr-xl-10"
            >
              <div className="row">
                <div 
                  className="shake col-lg-6 col-sm-6 pb-1 pt-1 pl-1 pr-1" 
                  data-aos="fade-down"
                  data-aos-delay={750}>
                  <AniLink duration={1}  paintDrip hex="#2196f3" to="/interactive-video-player">
                    <img className="w-100" src={imgInteractiveVideo} alt="Interactive Videos" />
                  </AniLink>                    
                </div>
                <div 
                  className="shake col-lg-6 col-sm-6 pb-1 pt-1  pl-1 pr-1"
                  data-aos="fade-right"
                  data-aos-delay={750}>
                  <AniLink duration={1}  paintDrip hex="#ff9b04" to="/interactive-images">
                    <img className="w-100" src={imgInteractiveImage} alt="Interactive Images" />
                  </AniLink>
                </div>
                <div 
                  className="shake col-lg-6 col-sm-6 pb-1 pt-1  pl-1 pr-1"
                  data-aos="fade-left"
                  data-aos-delay={750}>
                  <AniLink duration={1}  paintDrip hex="#e80ad2" to="/qr-codes">
                    <img className="w-100" src={imgBioLink} alt="Bio Link" />
                  </AniLink>
                </div>
                <div 
                  className="shake col-lg-6 col-sm-6 pb-1 pt-1  pl-1 pr-1"
                  data-aos="fade-up"
                  data-aos-delay={750}>
                  <AniLink duration={1}  paintDrip hex="#25b401" to="/popups">
                    <img className="w-100" src={imgPopups} alt="Popups" />
                  </AniLink>
                </div>
              </div>
            </div>
          </div>
          {/* End Hero Content */}
        </div>
      </div>
    </div>
  );
};

export default Hero;

