import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import iconOne from "../../assets/image/Images/hotspot.png";
import iconTwo from "../../assets/image/Images/settings.png";
import iconThree from "../../assets/image/Images/auto.png";

const ContentOne = ({ className, ...rest }) => {
  const widgetArray = [
    {
      title:
        "Embed interactive elements into your images",
      icon: iconThree,
    },    
    {
      title: "Customize everything ",
      icon: iconOne,
    },
    {
      title: "Automate adding tags & descriptions",
      icon: iconTwo,
    },
  ];

  return (
    <div className={`position-relative ${className}`} {...rest}>
      <div className="container position-static">
        <div className="row position-satatic justify-content-center justify-content-lg-start">
          <div className="pb-10 pb-lg-0 col-xl-7 col-lg-6 col-xs-10 position-static order-1 order-lg-1">
                <div className="digma-image" data-imageid="E8503EBB-E1A0-4C58-9C83-D538600C3A9E"><img alt="Digma Interactive Images" className="w-100" src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/149f86b9-723e-4cdb-8b6d-348ceb9cfd44.png" /></div>          
          </div>

          <div className="position-relative col-xl-5 col-lg-6 col-md-10 col-sm-10 order-2 order-lg-2">
            
            <div className="pr-md-20 pr-xl-5">
              {/* Section Title */}
              <div
                className="mb-5 mb-lg-5 pr-md-15 pr-lg-10 pr-xl-0"
              >
                <h2 className="font-size-10 mb-0">
                  <span className="Images">Images</span>
                </h2>
                <h3>Bring your images to life</h3>
                <p className="font-size-6 mb-0">
                  Add music, video, product carousels, contact forms and more to any image, and let our AI automatically add tags, descriptions and more. 
                </p>
              </div>
              {/* End Section Title */}
              <div className="list">
                {widgetArray.map(({ title, icon }, index) => {
                  return (
                    <div className="d-flex align-items-center mb-6" key={index}>
                      <div className="circle-59 bg-gray-3 mr-6">
                        <img src={icon} alt={title} />
                      </div>
                      <p
                        className="font-size-6 heading-default-color font-weight-medium mb-0"
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></p>
                    </div>
                  );
                })}
              </div>
              <AniLink className="mt-5 pl-10 pr-10 btn bg-image text-white btn-xl rounded-5 font-size-3" duration={1}  paintDrip hex="#ff9b04" to="/interactive-images">Learn more</AniLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentOne;
