import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import Slider from 'react-slick';

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    let dragging = false;

    const slickSettings = {
      autoplay: false,
      slidesToShow: 3,
      arrows: false,
      infinite: true,
      touchThreshold: 200,
      dots: true,
      beforeChange: () => (dragging = true),
      afterChange: () => (dragging = false),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 468,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="testimonial-one">
              <Slider
                {...slickSettings}
                css={`
                  .slick-dots {
                    display: flex !important;
                  }
                `}
              >
                {posts &&
                  posts.map(({ node: post }) => (
                    <div className="col-lg-6" key={post}>
                      <Link
                        onClick={(e) => dragging && e.preventDefault()}
                        className="title has-text-primary is-size-4 w-100"
                        to={post.frontmatter.slug}
                      >
                        <div
                          className="is-parent column is-6 bg-white d-xs-flex align-items-center mb-9 shadow-2 gr-hover-1"
                          key={post.id}
                        >
                          <article
                            className={`blog-list-item tile is-child box notification ${
                              post.frontmatter.featuredpost ? 'is-featured' : ''
                            }`}
                          >
                            <header className="align-items-center">
                              {post.frontmatter.thumbnail ? (
                                <div className="featured-thumbnail mb-5">
                                  <PreviewCompatibleImage
                                    imageInfo={{
                                      image: post.frontmatter.thumbnail,
                                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                    }}
                                  />
                                </div>
                              ) : null}
                              <div className="featured-copy">
                                <p className="font-size-6 font-weight-medium text-dark-cloud mb-5 line-height-26">
                                  {post.frontmatter.title}
                                </p>
                                <p className="post-meta">
                                  <span className="text-bali-gray font-size-3 pr-9">
                                    {post.frontmatter.date}
                                  </span>
                                </p>
                              </div>
                            </header>
                          </article>
                        </div>
                      </Link>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollHomeQuery {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              frontmatter {
                title
                slug
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
