import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import imageOne from "../../assets/image/pop-up.png";
import imageOneMobile from "../../assets/image/pop-up.png";
import iconOne from "../../assets/image/forms/hotspot.png";
import iconTwo from "../../assets/image/forms/settings.png";
import iconThree from "../../assets/image/forms/refresh.png";

const ContentOne = ({ className, ...rest }) => {
  const widgetArray = [
    {
      title:
        "Grow Sales & SignUps",
      icon: iconThree,
    },    
    {
      title: "Popup targeting",
      icon: iconOne,
    },
    {
      title: "Pre-built or custom templates",
      icon: iconTwo,
    },
  ];

  return (
    <div className={`position-relative ${className}`} {...rest}>
      <div className="container position-static mt-10">
        <div className="row position-static justify-content-center justify-content-lg-start">
          <div className="col-xl-7 col-lg-6 col-xs-10 position-static order-1 order-lg-1">
          <img className="d-none d-md-block w-100" src={imageOne} alt="Digma Content Management System" />
          <img className="d-md-none d-block w-100" src={imageOneMobile} alt="Digma Content Management System" />
          </div>
          <div className="col-xl-5 col-lg-6 col-md-10 col-sm-10 order-2 order-lg-2">
            <div className="pr-md-20 pb-5 pr-xl-5">
              {/* Section Title */}
              <div
                className="mb-5 mb-lg-5 pr-md-15 pr-lg-10 pr-xl-0">
                <h2 className="font-size-10 mb-0">
                  <span className="Forms">Popups</span>
                </h2>
                <h3>Popups that convert</h3>
                <p className="font-size-6 mb-0">
                Build your email marketing list, promote offers, collect feedback, upsell and cross-sell and improve your customers journey.
                </p>
              </div>
              {/* End Section Title */}
              <div className="list">
                {widgetArray.map(({ title, icon }, index) => {
                  return (
                    <div className="d-flex align-items-center mb-6" key={index}>
                      <div className="circle-59 bg-gray-3 mr-6">
                        <img src={icon} alt={title} />
                      </div>
                      <p
                        className="font-size-6 heading-default-color font-weight-medium mb-0"
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></p>
                    </div>
                  );
                })}
              </div>
              <AniLink className="mt-5 pl-10 pr-10 btn bg-form text-white btn-xl rounded-5 font-size-3" duration={1}  paintDrip hex="#25b401" to="/popups">Learn more</AniLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentOne;
