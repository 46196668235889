import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/home1/Hero';
import Company from '../sections/home1/Company';
import Videos from '../sections/home1/Videos';
import Images from '../sections/home1/Images';
import Links from '../sections/home1/Links';
import Popups from '../sections/home1/Popups';
import WaveReverse from '../sections/common/WaveReverse';
import Testimonial from '../sections/home1/Testimonial';
import Commit from '../sections/common/Commit';
import SEO from '../components/SEO';
import BlogRoll from '../components/BlogRoll/BlogRollHome';
import metaImg from '../assets/image/metaimg/default.jpg';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const IndexPage = ({ data }) => {
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (prevPos.y > currPos.y) {
      setShowReveal(false);
    } else {
      setShowReveal(true);
    }
  });
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-left',
          headerFluid: false,
          version: 'home-version',
          footerStyle: 'digma',
        }}
      >
        <SEO
          title="Make Your Content Interactive &amp; Increase Engagment"
          description="The Digma suite lets you create interactive videos, interactive images, popups, short links &amp; QR-codes — all in one platform!"
          image={metaImg}
        />
        <Hero className="position-relative pt-20 pt-md-20 position-relative" />
        <WaveReverse background="#fff" color="#F7F9FC" />
        <Popups className="pt-10 grey" />
        <div id="pop"></div>
        <div class="digma-popup" data-popupid="1E4BEC11-4B25-4C94-AFE7-C8229EBCC413"/>         
        <WaveReverse background="#F7F9FC" color="#fff" />
        <Links className="pt-10 pb-10" />
        <WaveReverse background="#fff" color="#F7F9FC" />
        <Videos className="pt-5 pb-0 pt-lg-10 pb-lg-20 grey" />
        <WaveReverse background="#F7F9FC" color="#fff" />
        <Images className="Images bg-white pt-20" />
        <WaveReverse
          className="d-none d-md-block"
          background="#fff"
          color="#F7F9FC"
        />
        <Company className="d-none d-md-block grey pt-13 pt-md-17 pt-lg-13" />
        <WaveReverse
          className="d-none d-md-block"
          background="#F7F9FC"
          color="#fff"
        />
        <Testimonial className="testimonial-one pt-20 pt-md-20 pt-lg-20 pb-5 pb-md-15 pb-lg-15" />
        <WaveReverse background="#fff" color="#F7F9FC" />
        <div className="homepage-latest grey pt-10 pb-15">
          <h5 className="h3 mb-10 text-center">Latest News</h5>
          <BlogRoll />
        </div>
        <div style={{ overflow: 'hidden', background: '#f7f9fc' }}>
          <Commit />
        </div>
        {/* <Popup trigger={open => (<button className={open ? 'hide' : `d-none d-md-flex btn bg-image btn-welcome btn-xl rounded-10 p-0 font-size-3 text-white ${showScrolling ? "hide" : ""} ${showReveal ? " " : ""}`}><div className="welcome-note">Hey! It's Stu</div><video className="w-100 rounded-10" muted autoPlay loop src={welcome}></video></button>)}
                    modal
                    nested
                   >
                    {close => (
                      <div className="modal">
                        <div className="content">       
                          <Helmet>
                          <link
                            href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=pop'}
                            rel="stylesheet"
                            />
                            <script src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=pop'}></script>         

                          </Helmet>  
                          <div className="digma" data-projectid="5DC86960-8347-4570-94A4-67DD64AF60D6"></div>
                        </div>
                      </div>
                    )}
        </Popup>           */}
        
      </PageWrapper>
    </>
  );
};
export default IndexPage;

export const pageQuery = graphql`
  query {
    bgImg: file(relativePath: { eq: "home-1/png/desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1240) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bgImg2: file(relativePath: { eq: "home-6/png/hero-mobile-empty.png" }) {
      childImageSharp {
        fluid(maxWidth: 1240) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
