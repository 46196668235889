import React from 'react';

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import imageOne from '../../assets/image/desktop-dashboard.jpg';
import imageTwo from '../../assets/image/mobile-ritual.png';
import imageOneMobile from '../../assets/image/mobile-dashboard.jpg';
import yellowCricle from '../../assets/paraimages/blue-circle.png';

import iconOne from '../../assets/image/icons/overlays.png';
import iconTwo from '../../assets/image/icons/branch.png';
import iconThree from '../../assets/image/icons/hotspots.png';

const ContentOne = ({ className, ...rest }) => {
  const widgetArray = [
    {
      title: 'Custom Overlays',
      icon: iconOne,
    },
    {
      title: 'Branching Videos',
      icon: iconTwo,
    },
    {
      title: 'Hotspot Videos',
      icon: iconThree,
    },
  ];

  return (
    <ParallaxProvider>
      <div className={`position-relative ${className}`} {...rest}>
        <div className="container position-static mt-10 pb-10 mt-lg-20 pb-lg-0">
          <div className="row position-static justify-content-center justify-content-lg-start">
            <div className="col-xl-5 col-lg-7 col-md-10 col-sm-10 order-2 order-lg-1">
              <div className="pr-md-20 pr-xl-5">
                {/* Section Title */}
                <div className="mb-5 mb-lg-5 pr-md-15 pr-lg-10 pr-xl-0">
                  <h2 className="font-size-10 mb-0">
                    <span className="light-blue">Videos</span>
                  </h2>
                  <h3>Create Interactive videos with ease</h3>
                  <p className="font-size-6 mb-0">
                    Whether you’re looking to add context, sell products,
                    capture lead—or just keep people watching for longer—Digma
                    lets you elevate your video content above the competition.
                  </p>
                </div>
                {/* End Section Title */}
                <div className="list">
                  {widgetArray.map(({ title, icon }, index) => {
                    return (
                      <div
                        className="d-flex align-items-center mb-6"
                        key={index}
                      >
                        <div className="circle-59 bg-gray-3 mr-6">
                          <img src={icon} alt={title} />
                        </div>
                        <p
                          className="font-size-6 heading-default-color font-weight-medium mb-0"
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></p>
                      </div>
                    );
                  })}
                </div>
                {/* <Popup
                    trigger={<button className="btn bg-video btn-xl mt-5 mr-5 rounded-5 font-size-3 text-white">Watch Demo</button>}
                    modal
                    nested
                   >
                    {close => (
                      <div className="modal">
                        <div className="content">
                          <Helmet>
                          <link
                            href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=videos'}
                            rel="stylesheet"
                            />
                            <script src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=videos'}></script>         

                          </Helmet>           
                          <div className="digma" data-projectid="A44BF93A-3829-456D-9B32-6D2F9FD94B83"></div>
                        </div>
                      </div>
                    )}
                  </Popup> */}
                <AniLink
                  className="btn bg-video btn-xl mt-5 mr-5 rounded-5 font-size-3 text-white"
                  paintDrip
                  hex="#2196f3"
                  to="/interactive-video-player"
                >
                  Learn More
                </AniLink>
                <AniLink
                  className="mt-5 pl-10 pr-10 btn btn-dodger-blue-2 btn-xl rounded-5 font-size-3"
                  paintDrip
                  hex="#2196f3"
                  to="/interactive-video-player"
                >
                  Watch Demo
                </AniLink>
              </div>
            </div>
            <div className="col-xl-7 col-lg-5 col-xs-10 pt-10 pt-lg-0 pb-0 pb-lg-20 order-1 order-lg-2">
              <div className="l3-content-image-group-4 position-lg-absolute mb-10 mb-lg-0">
                <div className="img-1 shadow-5 positon-relative">
                  <img
                    className="d-none d-md-block w-100 w-lg-auto"
                    src={imageOne}
                    alt="Digma Content Management System"
                  />
                  <img
                    className="d-md-none d-block w-100 w-lg-auto"
                    src={imageOneMobile}
                    alt="Digma Content Management System"
                  />
                  <div className="d-none d-md-block img-2 rounded-10">
                    <img
                      className="w-100 w-lg-auto"
                      src={imageTwo}
                      alt="Digma Mobile Content Management System"
                    />
                  </div>
                  <Parallax
                    className="absolute top"
                    translateY={['50px', '-200px']}
                    translateX={['50px', '-200px']}
                  >
                    <img
                      className="w-50 w-lg-100"
                      src={yellowCricle}
                      alt="yellow circle"
                    />
                  </Parallax>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default ContentOne;
