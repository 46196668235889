import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import imageOne from "../../assets/image/ritual-link.jpg";
import imageOneMobile from "../../assets/image/qr-mobile-home.jpg"
import pinkTriangle from "../../assets/paraimages/pink-triangle.png";
import iconOne from "../../assets/image/links/graph.png";
import iconTwo from "../../assets/image/links/link.png";
import iconThree from "../../assets/image/links/profile.png";

const ContentOne = ({ className, ...rest }) => {
  const widgetArray = [
    {
      title:
        "Full link management and analytics platform",
      icon: iconOne,
    },    
    {
      title: "Custom short links and QR codes",
      icon: iconTwo,
    },
    {
      title: "Next-gen link-in-bios",
      icon: iconThree,
    },
  ];

  return (
    <ParallaxProvider>   
    <div className={`position-relative ${className}`} {...rest}>
      <div className="container position-static mt-10">
        <div className="row position-static justify-content-center justify-content-lg-start">
          <div className="col-xl-5 col-lg-7 col-md-10 col-sm-10 order-2 order-lg-1">
            <div className="pr-md-20 pr-xl-5">
              {/* Section Title */}
              <div
                className="mb-5 mb-lg-5 pr-md-15 pr-lg-10 pr-xl-0"
              >
                <h2 className="font-size-10 mb-0">
                  <span className="Links">Dynamic QR Codes</span>
                </h2>
                <h3>Capture, manage and track your leads</h3>
                <p className="font-size-6 mb-0">
                  Capture leads via short links, custom QR codes or a personalized link-in-bio, and analyze your traffic with our full-suite analytics.
                </p>
              </div>
              {/* End Section Title */}
              <div className="list">
                {widgetArray.map(({ title, icon }, index) => {
                  return (
                    <div className="d-flex align-items-center mb-6" key={index}>
                      <div className="circle-59 bg-gray-3 mr-6">
                        <img src={icon} alt={title} />
                      </div>
                      <p
                        className="font-size-6 heading-default-color font-weight-medium mb-0"
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></p>
                    </div>
                  );
                })}
              </div>
              <AniLink className="mt-5 pl-10 pr-10 text-white btn bg-link btn-xl rounded-5 font-size-3" duration={1}  paintDrip hex="#e80ad2" to="/qr-codes">Learn more</AniLink>
            </div>
          </div>
          <div className="col-xl-7 col-lg-5 col-xs-10 pt-10 pt-lg-0 pb-0 pb-lg-20 order-1 order-lg-2">
            <div className="l3-content-image-group-4 position-lg-absolute mb-10 mb-lg-0">
              <div className="img-1 shadow-5 positon-relative">
              <img className="d-none d-md-block w-100 w-lg-auto" src={imageOne} alt="Digma Content Management System" />
              <img className="d-md-none d-block w-100 w-lg-auto" src={imageOneMobile} alt="Digma Content Management System" />
                <Parallax className="absolute top" translateY={['50px', '-200px']} translateX={['50px', '-200px']}>
                  <img className="w-50 w-lg-100" src={pinkTriangle} alt="pink triangle" />
                </Parallax>   
              </div>
            </div>
          </div>          
        </div>
      </div>
    </div>
    </ParallaxProvider>
  );
};

export default ContentOne;
